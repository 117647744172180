@import '../../assets/scss/general';

.venue {
  position: relative;
  border-bottom: 1px solid $color-grey;
  padding-bottom: 40px;
  margin-bottom: 40px;
  text-align: left;
  h3 {
      text-transform: uppercase;
      margin: 0 0 5px;
      font-size: 1.2rem;
  }
  &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
  }
  &.past {
      &:after {
          content: '';
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          bottom: 0;
          background-color: rgba($color-grey-light, .6);

      }
  }
  @media (min-width: $desktop) {
    .content {
      display:  flex;
      justify-content: space-between;
      //align-items: center;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: none;
      }
      > p {
        width: 25%;
        text-align: center;
        &:first-of-type {
          width: 50%;
          padding-right: 20px;
          text-align: left;
        }
        &:last-child {
          //text-align: right;
        }
      }
    }
  }
}