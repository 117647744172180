@import '../../assets/scss/general';

.post {
    background-color: $color-white;
    border: 1px solid  $color-grey;
    margin-bottom: 40px;
    text-align: left;
    border-radius: 5px;
    overflow: hidden;
    .images {
        .image {
            position: relative;
            display: flex;
            justify-content: center;
            background-color: $color-grey;
            /* background-position: center;
            background-size: cover; */
            a {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                bottom: 0;
            }
            img {
                align-self: center;
                max-width: 100%;
                height: auto;
            }
        }
    }
    .video {
      position: relative;
        div {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;
            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
        }
    }
    .content {
        .data {
            display: block;
            padding: 0 20px;
            font-size: 0.9rem;
            color: lighten($color-text, 20);
            margin: 20px 0 5px;
            text-transform: uppercase;
        }
        .title {
            padding: 0 20px 20px;
            border-bottom: 1px solid  $color-grey;
            // margin-bottom: 20px;
            h2 {
                margin-bottom: 0;
            }
        }
        .text {
            h2 {
                padding: 0 20px;
            }
            p {
                padding: 0 20px;
                margin-bottom: 20px;
                &:first-child {
                    margin-top: 20px;
                }
            }
            figure {
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: center;
                img {
                    max-width: 100%;
                    height: auto;
                    align-self: center;
                    object-fit: cover;
                }
            }
            
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    @media (min-width: $desktop) {
        padding-bottom: 20px;
        .content {
            .title {
                margin-bottom: 20px;
                p {
                    margin-top: 0;
                }
            }
        }
    }
}